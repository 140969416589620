$( document ).ready( function (){
  if ( $( "body" ).hasClass( "station/packages_new" ) ) {
    const init = async () => {
      let blobs;
      let rec;
      let stream;
      let preferredDevice = 0;
      let devices = (await navigator.mediaDevices.enumerateDevices()).filter(device => device.kind == 'videoinput');
      
      
      let getStream = async () => {
        const videoConstraint = { width: 1280, height: 720, deviceId: devices[preferredDevice % devices.length].deviceId };
        return await navigator.mediaDevices.getUserMedia({video: videoConstraint, audio: false});
      };
      
      captureBtn.onclick = async () => {
        stream = await getStream();
        videoElement.srcObject = stream;
        
        blobs = [];
        download.style.display = 'none';

        rec = new MediaRecorder(stream, {mimeType: 'video/webm; codecs=vp9,opus'});
        rec.ondataavailable = (e) => blobs.push(e.data);
        rec.onstop = async () => {
          let blob = new Blob(blobs, {type: 'video/webm'});
          let url = window.URL.createObjectURL(blob);
          console.log(url);
          download.href = url;
          download.download = $( "#order-number" ).text();
          download.style.display = 'block';
          setTimeout(function()
          {
            $( "#download" )[0].click();
          }, 300);
        };
        
        startBtn.disabled = false;
        captureBtn.disabled = true;
        startBtn.click();
      };

      startBtn.onclick = () => {
        startBtn.disabled = true;
        stopBtn.disabled = false;
        rec.start();
      };

      stopBtn.onclick = () => {
        captureBtn.disabled = false;
        startBtn.disabled = true;
        stopBtn.disabled = true;

        rec.stop();
        stream.getTracks().forEach(s=>s.stop())
        videoElement.srcObject = null
        stream = null;
      };
    };

    window.addEventListener('load', init);
  }
})