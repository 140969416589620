$( document ).ready( function (){
  if ( $( "body" ).hasClass( "station/packages_new" ) ) {
    spree_tracking_number = $( "#spree-tracking-number" ).text()
    $( "#tracking-number-check" ).keyup( function () {
      if (spree_tracking_number == $( "#tracking-number-check" ).val()) {
        $( "#tracking-number-result" ).html("<div class='text-success'>Match</div>")
      } else {
        $( "#tracking-number-result" ).html("<div class='text-danger'>Not Match</div>")
      }
    });
  }
})